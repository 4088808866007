import EditIcon from "@material-ui/icons/Edit";
import ExploreRoundedIcon from "@material-ui/icons/ExploreRounded";
import { Divider, Grid, IconButton, makeStyles, Tooltip, Typography } from "@material-ui/core";
import { Vehicle } from "shared/types";
import { Link } from "components/router";
import { useTranslation } from "react-i18next";
import { Dispatch, SetStateAction } from "react";
import { Description } from "@material-ui/icons";
import TailLiftIcon from "views/vehicle/components/truck-tail-lift.png";
import usePermissions from "shared/hooks/usePermissions";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { useState } from "react";

import VehicleGps from "components/VehicleGps";

type Props = {
  vehicle: Vehicle;
  index: number;
  array: Vehicle[];
  selected: Vehicle[];
  setSelected: Dispatch<SetStateAction<Vehicle[]>>;
};

const useStyles = makeStyles(theme => ({
  color: {
    "&:nth-of-type(even)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const VehicleListItem = ({ vehicle, selected, setSelected }: Props) => {
  const { t } = useTranslation();

  const { isAdmin, isCustomerService, checkPermissions } = usePermissions();

  const [open, setOpen] = useState(false);

  return (
    <>
      <Grid
        container
        style={{
          cursor: "pointer",
          alignItems: "center",
          height: 50,
          padding: "0 8px",
          backgroundColor: selected?.find(selectedVehicle => selectedVehicle.id === vehicle.id)
            ? "gainsboro"
            : undefined,
          color: vehicle?.status === "INACTIVE" ? "darkgrey" : undefined,
        }}
        onClick={() => {
          if (
            !selected?.length ||
            selected?.findIndex(selectedVehicle => selectedVehicle.id === vehicle.id) === -1
          ) {
            setSelected(prevState => {
              let temp = [...prevState];
              temp.push(vehicle);
              return temp;
            });
          } else {
            setSelected(prevState => {
              let temp = [...prevState];
              const id = temp.findIndex(selectedVehicle => selectedVehicle.id === vehicle.id);
              temp.splice(id, 1);
              return temp;
            });
          }
        }}
        className={useStyles().color}
      >
        <Grid item xs={!selected?.length ? 1 : 6}>
          <Typography>{vehicle.licensePlateNumber}</Typography>
        </Grid>
        <Grid item xs={!selected?.length ? 1 : 6}>
          <Typography>{vehicle.brand}</Typography>
        </Grid>
        {!selected?.length && (
          <>
            <Grid item xs={1}>
              <Typography>{vehicle.type}</Typography>
            </Grid>
            <Grid item xs={!selected?.length ? 2 : 6}>
              <Typography>
                {vehicle.user ? vehicle.user?.firstName + " " + vehicle.user?.lastName : "-"}
              </Typography>
            </Grid>
            <Grid item xs={!selected?.length ? 2 : 6}>
              <Typography>{vehicle.company.name}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{t("common:number", { num: vehicle.volume }) + " m³"}</Typography>
            </Grid>
            <Grid item xs={1}>
              <Typography>{t("common:number", { num: vehicle.weightCapacity }) + " kg"}</Typography>
            </Grid>
            <Grid item xs={1}>
              {!selected?.length && vehicle.tailLift ? (
                <Tooltip title={t("vehicle.properties.tailLift").toString()}>
                  <img src={TailLiftIcon} alt="" style={{ width: 24, height: 24, margin: 4 }} />
                </Tooltip>
              ) : (
                <div style={{ width: 24, margin: 4 }} />
              )}
            </Grid>
            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "flex-end",
              }}
            >
              {(isCustomerService ||
                isAdmin ||
                checkPermissions({
                  requestedPermissions: ["SHIPORGANIZER"],
                }) ||
                checkPermissions({
                  requestedPermissions: ["SHIPPERADMIN"],
                })) && (
                <>
                  <Tooltip title={t("common:button.modify").toString()}>
                    <IconButton component={Link} to={`/vehicle/modify/${vehicle.id}`}>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("vehicle.gps.title").toString()}>
                    <IconButton
                      onClick={event => {
                        event.stopPropagation();
                        setOpen(true);
                      }}
                    >
                      <LocationOnIcon />
                    </IconButton>
                  </Tooltip>
                </>
              )}

              <Tooltip title={t("vehicle.details.details").toString()}>
                <IconButton component={Link} to={`/vehicle/${vehicle.id}`}>
                  <Description />
                </IconButton>
              </Tooltip>

              <Tooltip title={t("tour.details").toString()}>
                <IconButton component={Link} to={`/tour/vehicle/${vehicle.id}`}>
                  <ExploreRoundedIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </>
        )}
      </Grid>
      <Divider />
      <VehicleGps vehicleId={vehicle.id} open={open} setOpen={setOpen} />
    </>
  );
};

export default VehicleListItem;
