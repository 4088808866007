export const BACKEND_URL =
  process.env.REACT_APP_BACKEND_URL || //"http://localhost:8081";
  // "https://arrakis-backend.demo.promera.systems"; //DEMO
  "https://arrakis-backend.dev.promera.systems"; //DEV
// "https://arrakis-api.carryall.hu";
// "https://backend.arrakis.carryall.hu";
// "http://192.168.88.201:8081"; //Fsifi
//"http://192.168.88.112:8081"; //N. Gábor
// "http://192.168.88.86:8081"; //F. Gábor
//"http://192.168.88.189:8081"; //Bálint

export const AUTH_TOKEN_KEY = "@arrakis@";
export const COOKIE_CONSENT = "@arrakis_cookie_consent";
export const NUMBER_FORMAT = /^[-+]?[0-9]?\.?[0-9]+$/;
//Lóránt
export const CAPTCHA_KEY = "6LfGOIMpAAAAACRQuhYUPtIh4jK5-PZOP4q11rzE";

export const EDITOR_PLUGINS =
  "print preview paste importcss searchreplace autolink " +
  "autosave save directionality code visualblocks image " +
  "link media codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime " +
  "advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons";

export const EDITOR_MENUBAR = "file edit view insert format tools table help";

export const EDITOR_TOOLBAR =
  "undo redo | bold italic underline strikethrough | " +
  "fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | " +
  "outdent indent | numlist bullist | forecolor backcolor removeformat | pagebreak | " +
  "charmap emoticons |  preview print | insertfile image media link anchor codesample | ltr rtl";

export const ARTICLE_TYPES = ["GDPR", "TOS"];
export const USER_TYPES = ["ADMIN", "CUSTOMER_SERVICE", "NORMAL"];
export const VEHICLE_BODY_TYPES = [
  "VAN",
  "CLOSED_BOX",
  "SHEETED_FLATBED",
  "REFRIGERATOR_VAN",
  "TRACTOR",
];

export const statuses = [
  "NEW_PACKAGE",
  "TAKEN_INTO_STORAGE",
  // "REMOVED_FROM_STORAGE",
  "GIVEN_TO_STOCK",
  "TAKEN_FROM_STOCK",
  "SUCCESSFUL_DELIVERY",
  "FAILED_DELIVERY",
  "RETURNED_TO_SHOP",
  "GIVEN_TO_BUYER",
  "DESTROYED",
  "DELETED",
  "HANDED_FOR_PACKAGE_DELIVERY",
  "FAILED_PICKUP",
  "READY_FOR_PICKUP",
  "REFUSED",
  "HANDED_FOR_DELIVERY_CUSTOMER",
  "MISSING_CDU",
  "DAMAGED_CDU",
];

export const SHIPPER_GIVEN_STATUSES = [
  "SUCCESSFUL_DELIVERY",
  "FAILED_DELIVERY",
  "GIVEN_TO_STOCK",
  "TAKEN_FROM_STOCK",
  "HANDED_FOR_PACKAGE_DELIVERY",
  "RETURNED_TO_SHOP",
  "HANDED_FOR_DELIVERY_CUSTOMER",
  "MISSING_CDU",
];

export const SHIPORGANIZER_GIVEN_STATUSES = [
  "SUCCESSFUL_DELIVERY",
  "FAILED_DELIVERY",
  "GIVEN_TO_STOCK",
  "TAKEN_FROM_STOCK",
  "HANDED_FOR_PACKAGE_DELIVERY",
  "HANDED_FOR_DELIVERY_CUSTOMER",
  "MISSING_CDU",
  "DAMAGED_CDU",
];

export const PICKUP_STATUSES = [
  "READY_FOR_PICKUP",
  "GIVEN_TO_BUYER",
  "FAILED_PICKUP",
  "TAKEN_INTO_STORAGE",
];

export const SHOPKEEPER_GIVEN_STATUSES = ["DELETED"];

export const WAREHOUSEKEEPER_GIVEN_STATUSES = ["TAKEN_INTO_STORAGE"];

export const AVAIBLE_VEHICLE_TYPES = [
  "TRUCK",
  "CAR",
  "MOTORCYCLE",
  "BICYCLE",
  "BOAT",
  "AIR",
  "OTHER",
];

export const AVAIBLE_FUEL_TYPES = ["ELECTRIC", "BIO", "DIESEL", "PETROL", "HYDROGEN"];

export const WAREHOUSEKEEPER_ADMIN_GIVEN_STATUSES = [
  "TAKEN_INTO_STORAGE",
  // "REMOVED_FROM_STORAGE",
  "GIVEN_TO_BUYER",
];

export const ROWS_PER_PAGE_OPTIONS = ["10", "20", "50", "100"];

export const SORT_BY = [
  "CREATED_ON",
  "SOURCE_DATE_FROM",
  "SOURCE_DATE_TO",
  "TARGET_DATE_FROM",
  "TARGET_DATE_TO",
  "PACKAGE_TRACKING_NUMBER",
  "SHOP_PACKAGE_NUMBER",
  "SOURCE_ADDRESS_ZIP",
  "SOURCE_ADDRESS_CITY",
  "SOURCE_ADDRESS_AREA",
  "TARGET_ADDRESS_ZIP",
  "TARGET_ADDRESS_CITY",
  "TARGET_ADDRESS_AREA",
  "CURRENT_ADDRESS_ZIP",
  "CURRENT_ADDRESS_CITY",
  "CURRENT_ADDRESS_AREA",
  "CUSTOMER_NAME",
  "CUSTOMER_EMAIL",
];

export const RETURN_GOODS_TYPES = [
  "WHITE_GOODS",
  "REMOVAL_BATHROOM",
  "REMOVAL_MATTRESS",
  "REMOVAL_SOFAS",
  "REMOVAL_KITCHEN",
  "REMOVAL_ELECTRONICS",
  "REMOVAL_ADDITIONAL",
];

export const AUDITLOG_TYPE = {
  USER_MODIFY: "user.modify",
  USER_LIST: "user.list",
  USER_GET: "user.get",
  USER_LIST_PAGEABLE: "user.listPageable",
  USER_FORGOTTEN_PASSWORD: "user.forgottenPassword",
  USER_FORGOTTEN_PASSWORD_FINISH: "user.forgottenPasswordFinish",
  USER_FORGOTTEN_PASSWORD_CANCEL: "user.forgottenPasswordCancel",
  USER_REGISTER: "user.register",
  USER_LOGOUT: "user.logout",
  USER_FINISH_REGISTRATION: "user.finishRegistration",
  USER_GET_BY_COMPANY: "user.getbycompany",
  USER_BAN: "user.ban",
  AUTHENTICATION: "authentication",
  COMPANY_CREATE: "company.create",
  COMPANY_DELETE: "company.delete",
  COMPANY_GET_BY_ID: "company.getById",
  COMPANY_LIST_BY_USER_ID: "company.listByUserId",
  COMPANY_LIST: "company.list",
  COMPANY_MODIFY: "company.modify",
  COMPANY_LIST_PAGEABLE: "company.listPageable",
  COMPANY_BAN: "company.ban",
  CONTACT_LIST_BY_PACKAGE_ID: "contact.listByPackageId",
  CONTACT_SEND_EMAIL: "contact.sendEmail",
  CONTACT_CREATE: "contact.create",
  CONTACT_MODIFY: "contact.modify",
  CONTACT_LIST_PAGEABLE: "contact.listPageable",
  CONTACT_GET_BY_ID: "contact.getById",
  CONTACT_LIST_BY_COMPANY_ID: "contact.listByCompanyId",
  CONTACT_LIST_BY_SHOP_ID: "contact.listByShopId",
  AUDIT_LOG_LIST: "auditLog.list",
  WAREHOUSE_CREATE: "warehouse.create",
  WAREHOUSE_GET: "warehouse.get",
  WAREHOUSE_LIST: "warehouse.list",
  WAREHOUSE_MODIFY: "warehouse.modify",
  WAREHOUSE_LINK_TO_USER: "warehouse.linktouser",
  PROPERTIES_MODIFY: "properties.modify",
  PROPERTIES_LIST: "properties.list",
  PROPERTIES_GET_SYSTEM_PROPERTIES: "properties.getSystemProperties",
  PROPERTIES_GET_BY_NAME: "properties.getByName",
  VEHICLE_CREATE: "vehicle.create",
  VEHICLE_MODIFY: "vehicle.modify",
  VEHICLE_SET_USER_ID: "vehicle.setUserId",
  VEHICLE_SET_STATUS: "vehicle.setStatus",
  SHOP_CREATE: "shop.create",
  SHOP_MODIFY: "shop.modify",
  PACKAGES_STATUS_CREATE: "packageStatus.create",
  PACKAGES_STATUS_UPDATE_BY_WAREHOUSE_KEEPER: "packagesStatus.updateByWarehouseKeeper",
  PACKAGES_STATUS_TRANSFER: "packagesStatus.transfer",
  PACKAGES_SEND_EMAIL: "packages.sendEmail",
  PACKAGES_REMOVE_FROM_TOUR: "packages.removeFromTour",
  ADDRESS_CREATE: "address.create",
  ADDRESS_GET: "address.get",
  ADDRESS_MODIFY_COORDINATES: "address.modifyCoordinates",
  DELIVERY_ZONE_CREATE_MULTIPLE: "deliveryZone.createMultiple",
  DELIVERY_ZONE_MODIFY: "deliveryZone.modify",
  DELIVERY_ZONE_DELETE: "deliveryZone.delete",
  DELIVERY_EXTRA_CHARGE_CREATE: "deliveryExtraCharge.create",
  DELIVERY_EXTRA_CHARGE_MODIFY: "deliveryExtraCharge.modify",
  DELIVERY_EXTRA_CHARGE_GET_BY_COMPANY_ID: "deliveryExtraCharge.getByCompanyId",
  GPS_GET_DISTANCES: "gps.getDistances",
  GPS_CREATE: "gps.create",
  DELIVERY_PRICE_CREATE_MULTIPLE: "deliveryPrice.createMultiple",
  STORAGE_CREATE_MULTIPLE: "storage.createMultiple",
  STORAGE_GET_ALL_FOR_WAREHOUSE: "storage.getAllForWarehouse",
  STORAGE_DELETE: "storage.delete",
  STORAGE_UPDATE_STATUS: "storage.updateStatus",
  ADD_ADDRESS_TO_SHOP: "shop.add.address",
  ARTICLE_SAVE: "article.save",
  ARTICLE_DELETE: "article.delete",
  ARTICLE_GET_ACTIVE_GENERAL_DATA_PROTECTION_REGULATION:
    "article.getActiveGeneralDataProtectionRegulation",
  ARTICLE_GET_ACTIVE_TERMS_OF_SERVICE: "article.getActiveTermsOfService",
  ARTICLE_LIST: "article.list",
  ARTICLE_LIST_BY_SUBJECT: "article.listBySubject",
  PACKAGES_CREATE: "packages.create",
  PACKAGES_MODIFY: "packages.modify",
  PACKAGES_CREATE_WITH_API_KEY: "packages.createWithApiKey",
  PACKAGES_GET_BY_TRACKING_NUMBER: "packages.getByTrackingNumber",
  TOUR_LIST_BY_VEHICLE_ID_AND_DATE: "tour.listByVehicleIdAndDate",
  TOUR_CREATE: "tour.create",
  TOUR_MODIFY_ADDRESS: "tour.modifyAddress",
  TOUR_SET_MULTIPLE_TO_FINAL: "tour.setMultipleToFinal",
  TOUR_MERGE: "tour.merge",
  TOUR_EXPORT: "tour.export",
  TOUR_DOWNLOAD: "tour.download",
  REL_TOUR_PACKAGES_MODIFY_PACKAGE_TOUR: "relTourPackages.modifyPackageTour",
  VEHICLE_LIST_NOT_ON_TOUR: "vehicle.listNotOnTour",
  VEHICLE_LIST_ON_TOUR: "vehicle.listOnTour",
  VEHICLE_GET: "vehicle.get",
  PACKAGES_GET_PDF: "packages.getPdf",
  NOTES_CREATE_WITH_FILE: "notes.createWithFile",
  NOTES_LIST_BY_SUBJECT_ID_AND_SUBJECT_TYPE: "notes.listBySubjectIdAndSubjectType",
  NOTES_GET_FILE: "notes.getFile",
  SHIPPING_PRICE_CREATE: "shippingPrice.create",
  SHIPPING_PRICE_GET_ALL_PAGEABLE: "shippingPrice.getAllPageable",
  SHIPPING_PRICE_MODIFY_NET_PRICE: "shippingPrice.modifyNetPrice",
  INVOICE_ITEM_CREATE: "invoiceItem.create",
  INVOICE_ITEM_MODIFY: "invoiceItem.modify",
  INVOICE_ITEM_CREATE_PDF: "invoiceItem.createPdf",
  INVOICE_CREATE: "invoice.create",
  FILE_GET: "file.get",
  RESOURCE_GET: "resource.get",
  DIRECTORY_ACCESS: "directory.access",
  PACKAGES_LIST_BY_WAREHOUSE_ID_AND_DELIVERY_DATE: "packages.listByWarehouseIdAndDeliveryDate",
  PACKAGES_MODIFY_DATES: "packages.modifyDates",
};

export const PACKAGE_NOTE_TYPES = [
  "PACKAGE_DAMAGED",
  "PACKAGE_NOT_HOME",
  "PACKAGE_NOT_PAYED",
  "PACKAGE_ADDRESS_OR_RECIPIENT_NOT_FOUND",
  "RESIGNED",
];

export const AREA_TYPES = [
  "UTCA",
  "UT",
  "TER",
  "KOZ",
  "KORUT",
  "LAKOTELEP",
  "SETANY",
  "AROK",
  "ATJARO",
  "DULO",
  "DULOUT",
  "ERDOSOR",
  "FASOR",
  "FORDULO",
  "GAT",
  "HATARSOR",
  "HATARUT",
  "KAPU",
  "KOROND",
  "KORTER",
  "LEJARO",
  "LEJTO",
  "LEPCSO",
  "LIGET",
  "MELYUT",
  "OROM",
  "OSVENY",
  "PARK",
  "PART",
  "PINCESOR",
  "RAKPART",
  "SIKATOR",
  "SOR",
  "SUGARUT",
  "TERE",
  "UDVAR",
  "UDULOPART",
];
