import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  makeStyles,
  MenuItem,
  Popover,
  TextField,
  Typography,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { KeyboardDatePicker } from "@material-ui/pickers";
import Button from "components/form/Button";
import { AREA_TYPES, USER_TYPES } from "config/constants";
import { RootState } from "config/store";
import { useState } from "react";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { listCompanies } from "shared/network/company.api";
import { Address, UserCompany } from "shared/types";
import { EMAIL_REGEX } from "shared/util/validation";

export type UserFormValues = {
  user: {
    firstName: string;
    lastName: string;
    email: string;
    userType: string;
    phone: string;
    identityCardNumber: string;
    addressCard: string;
    birthDate: Date | null;
    validityOfMedicalFitness: Date;
    temporaryAddress: Address;
    permanentAddress: Address;
    postAddress: Address;
    relUserCompanies: Partial<UserCompany>[];
  };
  isPickupPointUser: boolean;
  sameAddress: boolean;
  employeeNumber: string;
  licenseCategory: string;
  licenseExpirationDate: Date;
  licenseNumber: string;
  samePostAddressWithTemporary: boolean;
  temporaryAddressExist: boolean;
  samePostAddressWithPermanent: boolean;
};

const useStyles = makeStyles(() => ({
  title: {
    color: "grey",
    textTransform: "uppercase",
    fontSize: 14,
    paddingBottom: 8,
    borderBottom: "1px solid lightgrey",
    margin: "12px 0 18px",
  },
}));

type Props = {
  userId?: number;
  isModify: boolean;
};

const UserForm = ({ userId, isModify }: Props) => {
  const { t } = useTranslation();
  const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);
  const [companyFilter, setCompanyFilter] = useState("");

  const { register, errors, watch, setValue } = useFormContext<UserFormValues>();
  const classes = useStyles();

  const userCompanyFieldArray = useFieldArray({
    name: "user.relUserCompanies",
    keyName: "key",
  });

  const {
    samePostAddressWithTemporary,
    temporaryAddressExist,
    samePostAddressWithPermanent,
  } = watch([
    "samePostAddressWithTemporary",
    "temporaryAddressExist",
    "samePostAddressWithPermanent",
  ]);

  const companiesQuery = useQuery("companyList", async () => {
    const { data } = await listCompanies();
    return data.items;
  });

  const { user } = useSelector((state: RootState) => state.authentication);

  const userCompanies = watch("user.relUserCompanies", userCompanyFieldArray.fields);

  const unrelatedCompanies = companiesQuery?.data?.filter(
    company => !userCompanies.some(relField => relField.companyId === company.id.toString()),
  );

  return (
    <>
      <Grid container spacing={1} style={{ marginBottom: 16 }}>
        <Grid item xs={6}>
          <Box display="flex" flexDirection="column">
            {user?.userType === "ADMIN" && (
              <Controller
                name="user.userType"
                id="userType"
                defaultValue=""
                rules={{
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                }}
                render={props => (
                  <TextField
                    {...props}
                    label={t("user.properties.userType")}
                    InputLabelProps={{ shrink: true, required: true }}
                    error={!!errors.user?.userType}
                    helperText={errors.user?.userType?.message}
                    select
                  >
                    <MenuItem value="" disabled>
                      <em>{t("common:choose")}</em>
                    </MenuItem>
                    {USER_TYPES.map((type, index) => (
                      <MenuItem key={index} value={type}>
                        {t(`common:userType.${type}`)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            )}
            <Box display="flex" gridGap={10}>
              <TextField
                name="user.lastName"
                label={t("user.properties.lastName")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.user?.lastName}
                helperText={errors?.user?.lastName?.message}
              />
              <TextField
                name="user.firstName"
                label={t("user.properties.firstName")}
                InputLabelProps={{ shrink: true, required: true }}
                inputRef={register({
                  required: {
                    value: true,
                    message: t("validation:required"),
                  },
                })}
                error={!!errors?.user?.firstName}
                helperText={errors?.user?.firstName?.message}
              />
            </Box>
            <TextField
              name="user.email"
              type="email"
              label={t("common:field.email")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: { value: true, message: t("validation:required") },
                pattern: {
                  value: EMAIL_REGEX,
                  message: t("validation:invalidFormat", {
                    subject: t("validation:caption.subject.email"),
                  }),
                },
              })}
              error={!!errors.user?.email}
              helperText={errors.user?.email?.message}
            />
            <TextField
              name="user.phone"
              placeholder="+36"
              defaultValue="+36"
              label={t("common:field.phone")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.user?.phone}
              helperText={errors?.user?.phone?.message}
            />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="user.identityCardNumber"
            label={t("user.properties.identityCardNumber")}
            InputLabelProps={{ shrink: true }}
            inputRef={register()}
          />
          <TextField
            name="user.addressCard"
            label={t("user.properties.addressCard")}
            InputLabelProps={{ shrink: true }}
            inputRef={register()}
          />
          <Controller
            name="user.birthDate"
            defaultValue={null}
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                format="yyyy. MM. dd."
                label={"Születési idő"}
                disableFuture
              />
            )}
          />
          <Controller
            name="user.validityOfMedicalFitness"
            defaultValue={new Date()}
            render={({ ref, ...props }) => (
              <KeyboardDatePicker
                {...props}
                format="yyyy. MM. dd."
                label={t("user.properties.validityOfMedicalFitness")}
              />
            )}
          />
        </Grid>
      </Grid>
      {userCompanyFieldArray.fields.map((field, index) => (
        <Grid container alignItems="center" key={field.key}>
          <Grid item xs={4}>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
              {
                companiesQuery.data?.find(
                  company => company.id.toString() === field.companyId.toString(),
                )?.name
              }
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <input
              name={`user.relUserCompanies[${index}].id`}
              value={field.id}
              type="hidden"
              ref={register()}
            />
            <input
              name={`user.relUserCompanies[${index}].userId`}
              value={field.userId}
              type="hidden"
              ref={register()}
            />
            <input
              name={`user.relUserCompanies[${index}].companyId`}
              value={field.companyId}
              type="hidden"
              ref={register()}
            />
            <Controller
              name={`user.relUserCompanies[${index}].isShipper`}
              defaultValue={field.isShipper ?? false}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value}
                      onChange={e => {
                        props.onChange(e.target.checked);
                      }}
                    />
                  }
                  label={t("userCreate.isShipper")}
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              name={`user.relUserCompanies[${index}].isShipperAdmin`}
              defaultValue={field.isShipperAdmin ?? false}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value}
                      onChange={e => {
                        props.onChange(e.target.checked);
                        if (e.target.checked) {
                          setValue(`user.relUserCompanies[${index}].isShipper`, true);
                        }
                      }}
                    />
                  }
                  label={t("userCreate.isShipperAdmin")}
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              name={`user.relUserCompanies[${index}].isWarehouseKeeper`}
              defaultValue={field.isWarehouseKeeper ?? false}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  }
                  label={t("userCreate.isWarehouseKeeper")}
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              name={`user.relUserCompanies[${index}].isShipOrganizer`}
              defaultValue={field.isShipOrganizer ?? false}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  }
                  label={t("userCreate.isShipOrganizer")}
                  labelPlacement="end"
                />
              )}
            />
            <Controller
              name={`user.relUserCompanies[${index}].isShopkeeper`}
              defaultValue={field.isShopkeeper ?? false}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      color="primary"
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                    />
                  }
                  label={t("userCreate.isShopkeeper")}
                  labelPlacement="end"
                />
              )}
            />
          </Grid>
        </Grid>
      ))}
      {!!unrelatedCompanies?.length && (
        <Button
          variant="outlined"
          style={{ marginTop: 12, marginBottom: 20 }}
          onClick={event => setAnchor(event.currentTarget)}
          startIcon={<Add />}
        >
          {t("common:button.addToCompany")}
        </Button>
      )}
      <Divider />
      <Popover
        open={!!anchor}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
      >
        <Box padding="0 12px">
          <TextField
            value={companyFilter}
            onChange={e => setCompanyFilter(e.target.value)}
            label={t("common:button.search")}
          />
        </Box>
        <List style={{ maxHeight: 300, overflow: "auto" }}>
          {unrelatedCompanies
            ?.filter(company => company.name.toUpperCase().includes(companyFilter.toUpperCase()))
            .map(company => (
              <ListItem
                key={company.id}
                button
                onClick={() => {
                  userCompanyFieldArray.append({
                    companyId: company.id,
                    userId,
                    employeeNumber: "",
                    licenseCategory: "",
                    licenseExpirationDate: "",
                    licenseNumber: "",
                    isShipper: false,
                    isShipOrganizer: false,
                    isShopkeeper: false,
                    isWarehouseKeeper: false,
                  });
                  setAnchor(null);
                }}
              >
                {company.name}
              </ListItem>
            ))}
        </List>
      </Popover>
      {userCompanies.some(rel => rel.isShipper) && (
        <Grid container spacing={1}>
          <Grid item xs={3}>
            <TextField
              name="licenseNumber"
              defaultValue=""
              label={t("user.properties.licenseNumber")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors.licenseNumber}
              helperText={errors.licenseNumber?.message}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="licenseCategory"
              defaultValue=""
              label={t("user.properties.licenseCategory")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors.licenseCategory}
              helperText={errors.licenseCategory?.message}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              name="employeeNumber"
              defaultValue=""
              label={t("user.properties.employeeNumber")}
              InputLabelProps={{ shrink: true }}
              inputRef={register()}
              error={!!errors.employeeNumber}
              helperText={errors.employeeNumber?.message}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="licenseExpirationDate"
              defaultValue={new Date()}
              rules={{
                required: { value: true, message: t("validation:required") },
              }}
              render={({ ref, ...props }) => (
                <KeyboardDatePicker
                  {...props}
                  format="yyyy. MM. dd."
                  label={t("user.properties.licenseExpirationDate")}
                  error={!!errors.licenseExpirationDate}
                  helperText={errors.licenseExpirationDate?.message}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Controller
              name="isPickupPointUser"
              defaultValue={false}
              render={props => (
                <FormControlLabel
                  control={
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={e => props.onChange(e.target.checked)}
                      color="primary"
                    />
                  }
                  label={t("userCreate.isPickupPointUser")}
                  labelPlacement="end"
                />
              )}
            />
          </Grid>
        </Grid>
      )}

      <Box mt={2}>
        {!samePostAddressWithTemporary && (
          <>
            {!isModify && (
              <Controller
                name="samePostAddressWithPermanent"
                defaultValue={false}
                render={props => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={e => props.onChange(e.target.checked)}
                        color="primary"
                      />
                    }
                    label={t("userCreate.postAddressIsEqualWithPermanent")}
                    labelPlacement="end"
                  />
                )}
              />
            )}
          </>
        )}

        <Controller
          name="temporaryAddressExist"
          defaultValue={false}
          render={props => (
            <FormControlLabel
              control={
                <Checkbox
                  {...props}
                  checked={props.value}
                  onChange={e => props.onChange(e.target.checked)}
                  color="primary"
                />
              }
              label={t("userCreate.temporaryAddress")}
              labelPlacement="end"
            />
          )}
        />

        {temporaryAddressExist && !samePostAddressWithPermanent && (
          <>
            {!isModify && (
              <Controller
                name="samePostAddressWithTemporary"
                defaultValue={false}
                render={props => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={e => props.onChange(e.target.checked)}
                        color="primary"
                      />
                    }
                    label={t("userCreate.postAddressIsEqualWithTemporary")}
                    labelPlacement="end"
                  />
                )}
              />
            )}
          </>
        )}

        <Typography className={classes.title}>{t("userCreate.permanentAddress")}</Typography>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <TextField
              name="user.permanentAddress.country"
              label={t("common:field.address.country")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.user?.permanentAddress?.country}
              helperText={errors?.user?.permanentAddress?.country?.message}
              defaultValue="Magyarország"
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="user.permanentAddress.zipCode"
              type="number"
              label={t("common:field.address.zipCode")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.user?.permanentAddress?.zipCode}
              helperText={errors?.user?.permanentAddress?.zipCode?.message}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              name="user.permanentAddress.city"
              label={t("common:field.address.city")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.user?.permanentAddress?.city}
              helperText={errors?.user?.permanentAddress?.city?.message}
            />
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <TextField
              name="user.permanentAddress.areaName"
              label={t("common:field.address.areaName")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.user?.permanentAddress?.areaName}
              helperText={errors?.user?.permanentAddress?.areaName?.message}
            />
          </Grid>
          <Grid item xs={2}>
            <Controller
              name="user.permanentAddress.areaType"
              defaultValue="UTCA"
              render={props => (
                <TextField
                  {...props}
                  select
                  label={t("common:field.address.areaType")}
                  InputLabelProps={{ shrink: true, required: true }}
                  error={!!errors?.user?.permanentAddress?.areaType}
                  helperText={errors?.user?.permanentAddress?.areaType?.message}
                >
                  {AREA_TYPES.map(type => (
                    <MenuItem key={type} value={type}>
                      {t(`common:areaType.${type}`)}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: { value: true, message: t("validation:required") },
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="user.permanentAddress.houseNumber"
              label={t("common:field.address.houseNumber")}
              InputLabelProps={{ shrink: true, required: true }}
              inputRef={register({
                required: {
                  value: true,
                  message: t("validation:required"),
                },
              })}
              error={!!errors?.user?.permanentAddress?.houseNumber}
              helperText={errors?.user?.permanentAddress?.houseNumber?.message}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              name="user.permanentAddress.floor"
              label={t("common:field.address.floor")}
              InputLabelProps={{ shrink: true }}
              inputRef={register()}
            />
          </Grid>
        </Grid>
        {!samePostAddressWithPermanent && !samePostAddressWithTemporary && (
          <>
            <Typography className={classes.title}>{t("userCreate.postAddress")}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TextField
                  name="user.postAddress.country"
                  label={t("common:field.address.country")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.postAddress?.country}
                  helperText={errors?.user?.postAddress?.country?.message}
                  defaultValue="Magyarország"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="user.postAddress.zipCode"
                  type="number"
                  label={t("common:field.address.zipCode")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.postAddress?.zipCode}
                  helperText={errors?.user?.postAddress?.zipCode?.message}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  name="user.postAddress.city"
                  label={t("common:field.address.city")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.postAddress?.city}
                  helperText={errors?.user?.postAddress?.city?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="user.postAddress.areaName"
                  label={t("common:field.address.areaName")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.postAddress?.areaName}
                  helperText={errors?.user?.postAddress?.areaName?.message}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="user.postAddress.areaType"
                  defaultValue="UTCA"
                  render={props => (
                    <TextField
                      {...props}
                      select
                      label={t("common:field.address.areaType")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!errors?.user?.postAddress?.areaType}
                      helperText={errors?.user?.postAddress?.areaType?.message}
                    >
                      {AREA_TYPES.map(type => (
                        <MenuItem key={type} value={type}>
                          {t(`common:areaType.${type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="user.postAddress.houseNumber"
                  label={t("common:field.address.houseNumber")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.postAddress?.houseNumber}
                  helperText={errors?.user?.postAddress?.houseNumber?.message}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="user.postAddress.floor"
                  label={t("common:field.address.floor")}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register()}
                />
              </Grid>
            </Grid>
          </>
        )}
        {temporaryAddressExist && (
          <>
            <Typography className={classes.title}>{t("userCreate.temporaryAddress")}</Typography>
            <Grid container spacing={1}>
              <Grid item xs={5}>
                <TextField
                  name="user.temporaryAddress.country"
                  label={t("common:field.address.country")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.temporaryAddress?.country}
                  helperText={errors?.user?.temporaryAddress?.country?.message}
                  defaultValue="Magyarország"
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="user.temporaryAddress.zipCode"
                  type="number"
                  label={t("common:field.address.zipCode")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.temporaryAddress?.zipCode}
                  helperText={errors?.user?.temporaryAddress?.zipCode?.message}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  name="user.temporaryAddress.city"
                  label={t("common:field.address.city")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.temporaryAddress?.city}
                  helperText={errors?.user?.temporaryAddress?.city?.message}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  name="user.temporaryAddress.areaName"
                  label={t("common:field.address.areaName")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.temporaryAddress?.areaName}
                  helperText={errors?.user?.temporaryAddress?.areaName?.message}
                />
              </Grid>
              <Grid item xs={2}>
                <Controller
                  name="user.temporaryAddress.areaType"
                  defaultValue="UTCA"
                  render={props => (
                    <TextField
                      {...props}
                      select
                      label={t("common:field.address.areaType")}
                      InputLabelProps={{ shrink: true, required: true }}
                      error={!!errors?.user?.temporaryAddress?.areaType}
                      helperText={errors?.user?.temporaryAddress?.areaType?.message}
                    >
                      {AREA_TYPES.map(type => (
                        <MenuItem key={type} value={type}>
                          {t(`common:areaType.${type}`)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  }}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="user.temporaryAddress.houseNumber"
                  label={t("common:field.address.houseNumber")}
                  InputLabelProps={{ shrink: true, required: true }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: t("validation:required"),
                    },
                  })}
                  error={!!errors?.user?.temporaryAddress?.houseNumber}
                  helperText={errors?.user?.temporaryAddress?.houseNumber?.message}
                />
              </Grid>
              <Grid item xs={2}>
                <TextField
                  name="user.temporaryAddress.floor"
                  label={t("common:field.address.floor")}
                  InputLabelProps={{ shrink: true }}
                  inputRef={register()}
                />
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </>
  );
};

export default UserForm;
