import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type Props = {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  onSubmit: () => void;
};

const SMSWarning = ({ open, setOpen, onSubmit }: Props) => {
  const { t } = useTranslation();

  return (
    <Dialog open={!!open} onClose={() => setOpen(false)}>
      <DialogTitle>{t("tour.sms.confirmTitle")}</DialogTitle>
      <DialogContent>
        <Typography>{t("tour.sms.confirmBody")}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>{t("common:button.cancel")}</Button>
        <Button variant="contained" color="primary" onClick={() => onSubmit()}>
          {t("tour.yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SMSWarning;
